






































































































































































































































































































import Base from '@/mixins/Base.vue';
import { ILog, ILogsResponse } from '@/interfaces/logs';
import { IPagination } from '@/interfaces/pagination';
import { IUser } from '@/interfaces/user';
import { IClient } from '@/interfaces/client';

const component = Base.extend({
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        user: {
            type: Object as () => IUser,
            default: null,
        },

    },
    mounted() {
        this.getLogs();
    },
    data() {
        return {
            isAddUserClientModalActive: false,
            logs: {
                data: [] as Array<ILog>,
                meta: {} as IPagination | any,
            } as ILogsResponse,
            params: {
                action: '',
                name: '',
                sortBy: 'id',
                orderBy: 'desc',
                page: 1,
                client: null as IClient | any,
                user: null as IUser | any,
            },
        };
    },
    methods: {
        logHasData(row: ILog) {
            return !!row.data;
        },
        getLogs(page: number = 1): void {
            this.params.page = page;

            this.get<ILogsResponse>('logs', {
                params: {
                    ...this.params,
                    client_id: this.params.client ? this.params.client.id : null,
                    user_id: this.params.user ? this.params.user.id : null,
                },
            }).then(({ data }) => {
                this.logs = data;
            });
        },
    },
});

export default component;
